import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/Footer"

import svg1 from "../images/culture-illustrations/1.svg"
import svg2 from "../images/culture-illustrations/2.svg"
import svg3 from "../images/culture-illustrations/3.svg"
import svg4 from "../images/culture-illustrations/4.svg"

import CultureStyles from "./culture.module.css"
const Culture = () => {
  const [trigger1, setTrigger1] = useState(false)
  const [trigger2, setTrigger2] = useState(false)
  const [trigger3, setTrigger3] = useState(false)
  const [trigger4, setTrigger4] = useState(false)
  /*
  useEffect(() => {
    window.addEventListener("scroll", svgTrigger)
    svgTrigger()
    return () => {
      window.removeEventListener("scroll", svgTrigger)
    }
  }, [])

  const svgTrigger = () => {
    var scrollY = window.scrollY || window.pageYOffset

    //console.log(scrollY)

    var preContent = document.getElementById("preContent")
    var preContentHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger1Elem = document.getElementById("trigger1")
    var trigger1ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger2Elem = document.getElementById("trigger2")
    var trigger2ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger3Elem = document.getElementById("trigger3")
    var trigger3ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    var trigger4Elem = document.getElementById("trigger4")
    var trigger4ElemHeight =
      preContent && preContent.childNodes.length > 0
        ? preContent.scrollHeight
        : 0

    //TRIGGER 1 POINT
    const trigger1point = preContentHeight - trigger1ElemHeight / 2
    if (scrollY > trigger1point) {
      setTrigger1(true)
    } else {
      setTrigger1(false)
    }

    //TRIGGER 2 POINT
    const trigger2point =
      preContentHeight + trigger1ElemHeight - trigger2ElemHeight / 2
    if (scrollY > trigger2point) {
      setTrigger2(true)
    } else {
      setTrigger2(false)
    }

    //TRIGGER 3 POINT
    const trigger3point =
      preContentHeight +
      trigger1ElemHeight +
      trigger2ElemHeight -
      trigger3ElemHeight / 2
    if (scrollY > trigger3point) {
      setTrigger3(true)
    } else {
      setTrigger3(false)
    }

    //TRIGGER 4 POINT
    const trigger4point =
      preContentHeight +
      trigger1ElemHeight +
      trigger2ElemHeight +
      trigger3ElemHeight -
      trigger4ElemHeight / 2
    if (scrollY > trigger4point) {
      setTrigger4(true)
    } else {
      setTrigger4(false)
    }
  }
  */

  return (
    <Layout>
      <SEO title="Culture" />
      <h1 className={"hidden"}>Culture</h1>
      <div className="row mt-90" id="preContent">
        <div className="col-4 col-sm-hidden"></div>
        <div className="col-12 col-sm mb-70">
          <div className={CultureStyles.text}>
            As an integrated solution agency, we were established in 2012 to
            provide solutions on consultancy, events and digital product needs
            of our business partners and produce tailor-made projects. We keep
            growing with our values and put them in the heart of our works. We
            get results of our success stories in every field and increase our
            motivation.
          </div>
        </div>
      </div>
      <div className="row" id="trigger1">
        <div className={`col-4 col-sm-hidden ${CultureStyles.svg_wrapper}`}>
          <img
            alt="Culture"
            className={`${CultureStyles.svg_illustration} ${trigger1 &&
              CultureStyles.show_up}`}
            src={svg1}
          />
        </div>
        <div className="col-8 col-sm">
          <div className={CultureStyles.title}>
            We are connected with our clients and business partners.
          </div>
          <div className={CultureStyles.text}>
            We carried hundreds of products and services out on business design,
            experience design and interactive fields with our tens of customers
            since 2012. What drives and makes us stronger are our curiosity and
            innovative, human-oriented solutions that we present to our business
            partners.
          </div>
          <div className={CultureStyles.line}></div>
        </div>
      </div>
      <div className="row" id="trigger2">
        <div className={`col-4 col-sm-hidden ${CultureStyles.svg_wrapper}`}>
          <img
            alt="Culture"
            className={`${CultureStyles.svg_illustration} ${trigger2 &&
              CultureStyles.show_up}`}
            src={svg2}
          />
        </div>
        <div className="col-8 col-sm">
          <div className={CultureStyles.title}>
            We allow the freedom of success to ourselves and each other, proud
            of our work.
          </div>
          <div className={CultureStyles.text}>
            We trust each other, act explicitly, don’t run away from
            difficulties and utilize the opportunities that they present us. We
            are getting rid of bureaucracy with agile management approach, pump
            the team-spirit up. We are proud of our projects and the obstacles
            that we eliminated during processes.
          </div>
          <div className={CultureStyles.line}></div>
        </div>
      </div>
      <div className="row" id="trigger3">
        <div className={`col-4 col-sm-hidden ${CultureStyles.svg_wrapper}`}>
          <img
            alt="Culture"
            className={`${CultureStyles.svg_illustration} ${trigger3 &&
              CultureStyles.show_up}`}
            src={svg3}
          />
        </div>
        <div className="col-8 col-sm">
          <div className={CultureStyles.title}>
            We are trying to reach the best.
          </div>
          <div className={CultureStyles.text}>
            We are off the chains, never stop learning and develop ourselves.
            Our goal is to march on the best. We expanded our service areas by
            getting into an international partnership with Moodley Design Group
            on November 2019 to reflect the information’s surrounding power to
            our clients and business partners.
          </div>
          <div className={CultureStyles.line}></div>
        </div>
      </div>
      <div className="row" id="trigger4">
        <div className={`col-4 col-sm-hidden ${CultureStyles.svg_wrapper}`}>
          <img
            alt="Culture"
            className={`${CultureStyles.svg_illustration} ${trigger4 &&
              CultureStyles.show_up}`}
            src={svg4}
          />
        </div>
        <div className="col-8 col-sm">
          <div className={CultureStyles.title}>We value each other.</div>
          <div className={CultureStyles.text}>
            In a working environment where cultural diversity and individual
            differences of employees are respected, we have adopted the name of
            this place as “Manastır”, which are the places that information is
            shared and the meeting point of those who desire better.
          </div>
          <div className={CultureStyles.line}></div>
        </div>
      </div>
      <div className="row mb-100">
        <div className="col-12">
          <div className={CultureStyles.text}>
            We are now ready to spread the information, which is the keystone of
            sustainable development, globally by “beyond the line” projects on
            design, brand identity and industrial design areas as well as
            business design, experience design and interactive fields as
            Manastır Event, Tech, and Design Inc (part of moodley design group).
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Culture
